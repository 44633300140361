/**
 * @prettier
 */
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { validateCardApi } from 'src/api/pidedirecto/card/validateCardApi';
import { useConfirmDialog } from 'src/components/cart/ConfirmDialog';
import { Input } from 'src/components/Input';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { AppTheme } from 'src/styles/AppTheme';
import { CardVm } from 'src/types/CardVm';
import { alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport } from 'src/utils/alert/alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function ValidateCardDialog(): React.ReactElement | null {
    const classes = useStyles();

    const input: any = useRef(null);

    const [validationAmount, setValidationAmount] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const [card, setCard] = useState<CardVm>();

    const open = useSelector((state) => state.app.validateCardDialog.open);
    const dialogCard = useSelector((state) => state.app.validateCardDialog.card);
    const onValidatedCard = useSelector((state) => state.app.validateCardDialog.onValidatedCard);

    const closeValidateCardDialog = useAction(actions.closeValidateCardDialog);
    const updateCard = useAction(actions.updateCard);

    const confirm = useConfirmDialog();

    useEffect(() => {
        if (open) {
            clearTimeout(timeout);
            setValidationAmount('');
            setIsSubmitting(false);
            setError(undefined);
            setTimeout(() => {
                input?.current?.focus();
            }, 300);
        }
    }, [open]);

    useEffect(() => {
        setCard(dialogCard);
    }, [dialogCard]);

    const handleClickValidate = async () => {
        setIsSubmitting(true);
        setError(undefined);

        const response = await validateCardApi({
            cardId: requireValue(card?.cardId),
            validationAmount: `${validationAmount.substring(0, 2)}.${validationAmount.substring(2, 4)}`,
        });
        setIsSubmitting(false);

        if (!response.ok) {
            alertSomethingWentWrongCheckYourInternetConnectionOrContactSupport();
            return;
        }
        const updatedCard = response.data;
        updateCard(updatedCard);
        setCard(updatedCard);

        if (!updatedCard?.validated) {
            setError(translate('Entered amount do not match amount charged.'));
            setValidationAmount('');
            setTimeout(() => {
                input?.current?.focus();
            }, 300);
            return;
        }

        closeValidateCardDialog();

        onValidatedCard?.(updatedCard);
    };

    const openWhyCardValidationDialog = async () => {
        await confirm({
            title: translate('Why is it necessary?'),
            content: translate('The validation of your card is a method to ensure that no one else is using your card fraudulently, thus protecting you from unrecognized charges.'),
        });
    };

    const getInputStyle = () => {
        if (validationAmount?.length === 6) {
            return classes.inputCodeEntered;
        }
        if (isSubmitting) {
            return classes.inputInProgress;
        }
        return {};
    };

    if (!card) {
        return null;
    }

    if (card.blocked) {
        return (
            <Dialog open={open} title={translate('Card blocked')} onClose={closeValidateCardDialog} classes={{ dialog: classes.dialog }}>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Text style={{ textAlign: 'center' }}>
                                {translate('You have tried to validate card @cardNumber too many times. Please try again or contact support.', { cardNumber: card?.cardNumber })}
                            </Text>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Button onClick={closeValidateCardDialog} classes={{ button: classes.button }}>
                    {translate('Ok')}
                </Button>
            </Dialog>
        );
    }

    return (
        <Dialog open={open} title={translate('You need to validate your card')} onClose={closeValidateCardDialog} loading={isSubmitting} classes={{ dialog: classes.dialog }}>
            <DialogContent className={classes.dialogContent}>
                <Text>
                    {translate(
                        'We have placed a hold of less than $20 on your card. Please confirm if you have received a notification/SMS from your bank or log in to your online banking to identify the amount withheld and enter it here. Fraud prevention protocol.',
                    )}
                </Text>
                <Grid item xs={12}>
                    <Input
                        name={'validation'}
                        inputRef={input}
                        autoFocus
                        value={validationAmount}
                        onChange={(value: string) => setValidationAmount(value)}
                        onKeyDown={(e: React.KeyboardEvent) => {
                            if ((e.key || e.code) === 'Enter') handleClickValidate();
                        }}
                        disabled={isSubmitting}
                        classes={{ input: classNames(classes.input, getInputStyle()) }}
                        helperText={!isProductionEnvironment() ? translate('Your in a dev environment. Use amount $11.11 to validate amount.') : ''}
                        InputComponent={NumberFormatCustom}
                        autoComplete='off'
                    />
                    <div className={classes.termsContainer}>
                        <span className={classes.conditionsContainer} onClick={openWhyCardValidationDialog}>
                            {translate('Why is it necessary?')}
                        </span>
                    </div>
                </Grid>
                {!!error && (
                    <Grid item xs={12} container justify='center'>
                        <Text error>{translate(error)}</Text>
                    </Grid>
                )}
            </DialogContent>
            <Button onClick={handleClickValidate} disabled={validationAmount?.length !== 4 || isSubmitting} classes={{ button: classes.button }}>
                {isSubmitting && <CircularProgress size={40} style={{ position: 'absolute' }} />}
                {translate('Verify')}
            </Button>
        </Dialog>
    );
}

const timeout: any = null;

function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => onChange(values.value)}
            thousandSeparator={false}
            isNumericString
            format='$##.##'
            allowEmptyFormatting
            mask='X'
            decimalScale={0}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'center',
        color: AppTheme.text.light,
        fontSize: 36,
        height: 40,
        margin: 0,
        textTransform: 'uppercase',
        '&[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    inputCodeEntered: {
        color: theme.palette.primary.main,
    },
    inputInProgress: {
        color: AppTheme.input.colorDisabled,
    },
    termsContainer: {
        display: 'flex',
        width: '100%',
        marginTop: 10,
        marginBottom: 15,
        justifyContent: 'center',
    },
    conditionsContainer: {
        display: 'flex',
        color: '#1a8074',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontFamily: AppTheme.typography.regular,
    },
    dialog: {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        border: 0,
    },
    dialogContent: {
        paddingRight: 24,
        paddingLeft: 24,
        marginBottom: 10,
    },
    button: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        height: 64,
        fontSize: 16,
    },
}));
