/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { CircleIconContainer, Dialog, Text } from '@pidedirecto/ui';
import { CashIcon, CreditCardIcon, TerminalIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RestaurantPaymentMethodVm } from 'src/api/pidedirecto/payment/RestaurantPaymentMethodVm';
import { runAfterCheckAnimation } from 'src/components/BottomDialogCheckButton';
import { Button } from 'src/components/Button';
import { PaymentMethodIcon } from 'src/components/cart/PaymentMethodIcon';
import { CustomerCards } from 'src/components/cart/selectPaymentMethodDialog/CustomerCards';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { actions } from 'src/reducers';
import { createUserSelectedPaymentMethodLogEvent } from 'src/services/logEvent/createUserSelectedPaymentMethodLogEvent';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import { isCardOnDeliveryPayment } from 'src/utils/paymentMethod/isCardOnDeliveryPayment';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isCreditPayment } from 'src/utils/paymentMethod/isCreditPayment';
import { isTransferPayment } from 'src/utils/paymentMethod/isTransferPayment';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isPidedirectoPayment } from 'src/utils/restaurantPaymentMethod/isPidedirectoPayment';

export function SelectRestaurantPaymentMethodDialog({ isPaymentLink }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const open = useSelector((state) => state.app.selectPaymentMethodDialog.open);
    const orderType = useSelector((state) => state.app.orderType);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const restaurantPaymentMethods = useSelector((state) => state.app.restaurant?.restaurantPaymentMethods);
    const ecommerceCashPaymentsToEatHereEnabled = useSelector((state) => state.app.restaurant?.ecommerceCashPaymentsToEatHereEnabled);
    const inAppPaymentsEnabled = useSelector((state) => state.app.restaurant?.inAppPaymentsEnabled);
    const maximumCashOrderAmount = useSelector((state) => state.app.restaurant?.maximumCashOrderAmount);
    const subtotal = useSelector((state) => state.app.payment?.subtotal);
    const productDiscount = useSelector((state) => state.app.payment?.productDiscount);
    const currencyFormat = useSelector((state) => state.app.restaurantMenu?.currencyFormat);
    const customPaymentMethod = useSelector((state) => state.app.customPaymentMethod);

    const closeSelectPaymentMethodDialog = useAction(actions.closeSelectPaymentMethodDialog);
    const selectPaymentMethod = useAction(actions.selectPaymentMethod);
    const openAddNewCardDialog = useAction(actions.openAddNewCardDialog);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethod);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const cashAmountHigherThanPermitted = BigNumber(subtotal ?? 0)
        .minus(productDiscount ?? 0)
        .isGreaterThanOrEqualTo(maximumCashOrderAmount);

    const cashPaymentsDisabled = (cashPayment?: RestaurantPaymentMethodVm) => {
        if (!cashPayment) return false;
        return (!cashPayment?.enabled && orderType !== OrderTypes.TABLE_ORDER) || cashAmountHigherThanPermitted || (!ecommerceCashPaymentsToEatHereEnabled && orderType === OrderTypes.TABLE_ORDER);
    };

    const isPaymentOnDelivery = (restaurantPaymentMethods: Array<RestaurantPaymentMethodVm>) => {
        const cardPaymentInfo = restaurantPaymentMethods.find(
            (restaurantPaymentMethods) => isPidedirectoPayment(restaurantPaymentMethods.restaurantPaymentMethodType) && isCardPayment(restaurantPaymentMethods.paymentMethod),
        );
        const cashPaymentInfo = restaurantPaymentMethods.find(
            (restaurantPaymentMethods) => isPidedirectoPayment(restaurantPaymentMethods.restaurantPaymentMethodType) && isCashPayment(restaurantPaymentMethods.paymentMethod),
        );

        return isPaymentLink || !cashPaymentsDisabled(cashPaymentInfo) || cardPaymentInfo?.enabled;
    };

    useEffect(() => {
        if (open) {
            createUserSelectedPaymentMethodLogEvent({
                paymentMethodChangedFrom: selectedPaymentMethod,
                paymentMethodChangedTo: paymentMethod,
            });
            setSelectedPaymentMethod(paymentMethod);
        }
    }, [open]);

    const getCashPaymentSubText = () => {
        if (cashAmountHigherThanPermitted) return translate(`The limit of cash payments is @amount`, { amount: formatAsCurrencyNumber(maximumCashOrderAmount, currencyFormat) });
        return undefined;
    };

    const handleSelectCard = () => {
        setSelectedPaymentMethod(PaymentMethods.CREDIT_CARD);
    };

    const handleAddNewCard = () => {
        closeSelectPaymentMethodDialog();
        openAddNewCardDialog();
    };

    const handleCheckPaymentDisabled = (restaurantPaymentMethod: RestaurantPaymentMethodVm) => {
        if (!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)) {
            return !restaurantPaymentMethod.enabled;
        }
        if (isCashPayment(restaurantPaymentMethod.paymentMethod)) {
            return (!restaurantPaymentMethod.enabled && orderType !== OrderTypes.TABLE_ORDER) || (!ecommerceCashPaymentsToEatHereEnabled && orderType === OrderTypes.TABLE_ORDER);
        }
        if (isCardPayment(restaurantPaymentMethod.paymentMethod)) {
            return !restaurantPaymentMethod.plexoStoreId || !restaurantPaymentMethod.enabled;
        }
        if (isCardOnDeliveryPayment(restaurantPaymentMethod.paymentMethod) || isTransferPayment(restaurantPaymentMethod.paymentMethod)) {
            return !restaurantPaymentMethod.enabled;
        }
        return !restaurantPaymentMethod.enabled;
    };

    const isPaymentMethodDisabled = (restaurantPaymentMethod: RestaurantPaymentMethodVm) => {
        if (isCashPayment(restaurantPaymentMethod.paymentMethod) && isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)) {
            return cashAmountHigherThanPermitted || !restaurantPaymentMethod.enabled || isSubmitting || !restaurantPaymentMethod?.orderTypes?.includes(orderType);
        }
        return !restaurantPaymentMethod.enabled || !restaurantPaymentMethod?.orderTypes?.includes(orderType) || isSubmitting;
    };

    const onSelectPaymentMethod = (restaurantPaymentMethod: RestaurantPaymentMethodVm) => {
        if (isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)) {
            setSelectedPaymentMethod(restaurantPaymentMethod.paymentMethod);
            selectPaymentMethod({ paymentMethod: restaurantPaymentMethod.paymentMethod });
            runAfterCheckAnimation(closeSelectPaymentMethodDialog);
            return;
        }

        setSelectedPaymentMethod(restaurantPaymentMethod.paymentMethod);
        const customPaymentMethodObject = {
            amount: restaurantPaymentMethod.commission ?? 0,
            customPaymentMethod: restaurantPaymentMethod.name,
            paymentMethod: restaurantPaymentMethod.paymentMethod,
            mexicanPaymentMethodCode: restaurantPaymentMethod.mexicanPaymentMethodCode,
        } as const;

        selectPaymentMethod({ paymentMethod: restaurantPaymentMethod.paymentMethod, customPaymentMethod: customPaymentMethodObject });
        runAfterCheckAnimation(closeSelectPaymentMethodDialog);
    };

    const getPaymentMethodIcon = (paymentMethod: PaymentMethod) => {
        if (isCashPayment(paymentMethod)) return <CashIcon size={25} title={translate('Cash icon')} />;
        if (isCardPayment(paymentMethod)) return <CreditCardIcon title={translate('Card')} />;
        if (isCardOnDeliveryPayment(paymentMethod)) return <TerminalIcon size={35} title={translate('Terminal icon')} />;
    };

    if (!isPaymentOnDelivery && !inAppPaymentsEnabled) {
        return (
            <Dialog title={translate('There are no payment methods configured')} open={open}>
                <Button onClick={closeSelectPaymentMethodDialog}>{translate('Continue')}</Button>
            </Dialog>
        );
    }

    return (
        <Dialog classes={{ dialog: classes.dialogContent }} loading={isSubmitting} open={open} title={translate('How do you want to pay?')} onClose={closeSelectPaymentMethodDialog}>
            {isPaymentOnDelivery(restaurantPaymentMethods) && (
                <div>
                    <h3 className={classes.titleSection}>{translate('Payment on delivery')}</h3>
                    <div className={classes.paymentContainer}>
                        {restaurantPaymentMethods.length > 0 &&
                            restaurantPaymentMethods.map((restaurantPaymentMethod) => {
                                if (handleCheckPaymentDisabled(restaurantPaymentMethod) || isCreditPayment(restaurantPaymentMethod.paymentMethod)) return null;
                                return (
                                    <div className={classes.paymentContent}>
                                        <Button
                                            outlined
                                            color={
                                                !isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)
                                                    ? restaurantPaymentMethod?.name === customPaymentMethod?.customPaymentMethod
                                                        ? '#06B7A2'
                                                        : '#8C9196'
                                                    : restaurantPaymentMethod?.paymentMethod === selectedPaymentMethod && !customPaymentMethod?.customPaymentMethod
                                                    ? '#06B7A2'
                                                    : '#8C9196'
                                            }
                                            classes={{ button: isPaymentMethodDisabled(restaurantPaymentMethod) ? classes.buttonContainerDisabled : classes.buttonContainer }}
                                            onClick={() => onSelectPaymentMethod(restaurantPaymentMethod)}
                                            disabled={
                                                isCashPayment(restaurantPaymentMethod.paymentMethod) && isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)
                                                    ? cashAmountHigherThanPermitted || !restaurantPaymentMethod.enabled || !restaurantPaymentMethod?.orderTypes?.includes(orderType) || isSubmitting
                                                    : !restaurantPaymentMethod.enabled || !restaurantPaymentMethod?.orderTypes?.includes(orderType) || isSubmitting
                                            }
                                        >
                                            <div className={classes.buttonContent}>
                                                {isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && (
                                                    <>
                                                        <CircleIconContainer>{getPaymentMethodIcon(restaurantPaymentMethod.paymentMethod)}</CircleIconContainer>
                                                        <Text className={classNames(classes.text)}>
                                                            {isCardOnDeliveryPayment(restaurantPaymentMethod.paymentMethod) ? translate('Terminal') : translate(restaurantPaymentMethod.paymentMethod)}
                                                        </Text>
                                                    </>
                                                )}
                                                {!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && (
                                                    <>
                                                        <CircleIconContainer>
                                                            <PaymentMethodIcon icon={restaurantPaymentMethod.icon} />
                                                        </CircleIconContainer>
                                                        <Text className={classNames(classes.text)}>{translate(restaurantPaymentMethod.name)}</Text>
                                                    </>
                                                )}
                                            </div>
                                        </Button>
                                        {isCashPayment(restaurantPaymentMethod.paymentMethod) && isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && (
                                            <Text className={classNames(classes.subtextButton)}>{getCashPaymentSubText()}</Text>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
            {inAppPaymentsEnabled && (
                <CustomerCards
                    selectedPaymentMethod={selectedPaymentMethod}
                    disabled={isSubmitting}
                    onSelectCard={handleSelectCard}
                    onStartLoading={() => setIsSubmitting(true)}
                    onEndLoading={() => setIsSubmitting(false)}
                />
            )}
            {inAppPaymentsEnabled && (
                <Button onClick={handleAddNewCard} disabled={isSubmitting || !inAppPaymentsEnabled}>
                    {translate('+ New Card')}
                </Button>
            )}
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
    },
    card: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    titleSection: {
        color: '#2E3748',
        fontSize: 14,
        fontFamily: AppTheme.typography.light,
    },
    paymentContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: 8,
        rowGap: 7,
    },
    buttonContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        margin: 0,
        gap: 12,
    },
    subtextButton: {
        fontSize: 10,
        color: AppTheme.listRowButton.subtext.color,
        marginTop: 4,
        marginLeft: 3,
    },
    dialogContent: {
        gap: 24,
        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
    },
    text: {
        textAlign: 'left',
        fontFamily: AppTheme.typography.regular,
        color: '#4f586e',
        fontSize: 14,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        width: '100%',
    },
    buttonContainerDisabled: {
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        width: '100%',
        border: 'none',
        '&:hover': {
            border: 'none',
        },
    },
    paymentContent: {
        width: '100%',
    },
}));

type Props = {
    isPaymentLink?: boolean;
};
